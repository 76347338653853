$(function () {
  let triggerIntegration = function () {
    let hash = window.location.hash || ""

    if (
      hash.startsWith("#partner-integration-") ||
      hash.startsWith("#legacy-partner-integration-")
    ) {
      let component = $(hash)
      let cta = component.find(".integration-button")

      if (cta.length > 0) {
        window.location.hash = ""
        cta.get(0).click()
      }
    }
  }

  if (window.location.hash) {
    triggerIntegration()
  }

  $(window).on("hashchange", function () {
    triggerIntegration()
  })
})
