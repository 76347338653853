/**
 * Integrations which trigger a self-hosted form, as opposed to Calendly.
 *
 * Show the modal,
 * fetch the inner form in the background,
 * shove it in the placeholder.
 */

$(function () {
  $("a.js-integration-trigger").on("click tap", function (e) {
    e.preventDefault()

    var targetModal = $("#js-integration-modal")
    if (targetModal) {
      // show placeholder
      targetModal.removeClass("hidden")

      // fetch local content and replace the placeholder
      var contentLink = $(e.target).attr("href")
      if (contentLink) {
        fetch(contentLink)
          .then(function (response) {
            return response.text()
          })
          .then(function (html) {
            var container = targetModal.find(".js-modal-content")
            targetModal.find(".js-modal-loading").addClass("hidden")

            $("body").addClass("overflow-hidden")

            var wrapper = $("<div>").addClass("js-modal-wrapper")
            wrapper.append($(html))
            container.append(wrapper)

            const doCancel = function (canx) {
              canx.preventDefault()
              targetModal.find(".js-modal-wrapper").remove()
              targetModal.find(".js-modal-loading").removeClass("hidden")
              targetModal.addClass("hidden")
              $("body").removeClass("overflow-hidden")
              return false
            }

            // the cancel button might not have existed until the form appeared
            $(".js-modal-cancel").on("click tap", doCancel)
            targetModal.find("form").on("keyup", function (canx) {
              return canx.key === "Escape" ? doCancel(canx) : true
            })

            // wire up the form handler so that we can replace the
            // modal content with the outcome of booking
            $("form.js-self-hosted-booking").on("submit", function (e) {
              e.preventDefault()

              targetModal.children(".js-modal-loading").addClass("hidden")
              wrapper.children(".js-modal-complete").addClass("hidden")
              wrapper.children(".js-modal-failed").addClass("hidden")

              var form = $(e.target)
              var where = form.attr("action")
              var data = form.serialize()
              console.log("Posting", data, "to", where)
              fetch(where, {
                method: "POST",
                body: data,
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              })
                .then(function (response) {
                  form.addClass("hidden")
                  if (response.ok) {
                    wrapper.children(".js-modal-complete").removeClass("hidden")
                  } else {
                    wrapper.children(".js-modal-failed").removeClass("hidden")
                  }
                })
                .catch(function (err) {
                  console.warn(err)

                  form.addClass("hidden")
                  wrapper.children(".js-modal-complete").addClass("hidden")
                  wrapper.children(".js-modal-failed").removeClass("hidden")
                })
            })
          })
          .catch(function (err) {
            console.warn(err)
            targetModal.addClass("hidden")
          })
      }
    }
    return false
  })
})
