/**
 * Set up sharing and favourite-article toggle buttons.
 *
 * See the 'share.pug' mixin.
 *
 * (It's either DOM access and for-loops, or bundling JQuery for a few calls.)
 */

import ClipboardJS from "clipboard"

window.addEventListener("load", function () {
  // SHARE BUTTON
  new ClipboardJS("button.js-share-link")

  // FAVOURITES
  // a) Inside these click listeners:
  //    - 'this' is the listener object,
  //    - event.target is the click receiver, *not* necessarily the listener.
  // b) The article is a favourite by the value of data-item-favourite
  //    (as dataset.itemFavourite) with the **literal words** 'true' and 'false'.
  // c) The article id itself is accessible as data-item-id / dataset.itemId.
  // d) The buttons are toggled betwen add/remove optimistically without waiting
  //    for the back-end process, which can be pretty slow.

  var adders = this.document.getElementsByClassName("js-favourite-add")
  var removers = this.document.getElementsByClassName("js-favourite-remove")

  const showItems = function (aList /* of elements */) {
    toggleItems(aList, true)
  }

  const hideItems = function (aList /* of elements */) {
    toggleItems(aList, false)
  }

  const toggleItems = function (
    aList /* of elements */,
    setVisible /* boolean */
  ) {
    for (var i = 0; i < aList.length; i++) {
      if (setVisible) {
        aList[i].classList.remove("hidden")
      } else {
        aList[i].classList.add("hidden")
      }
    }
  }

  for (var i = 0; i < adders.length; i++) {
    adders[i].addEventListener("click", function (event) {
      event.preventDefault()

      // optimistically switch the button and let the back-end process happen
      hideItems(adders)
      showItems(removers)

      let id = this.dataset.itemId
      if (id) {
        fetch(`/users/favourite/${id}`, { method: "PUT" })
          .then(function () {
            return null
          })
          .catch(function (err) {
            console.warn(err)
          })
      }
      return false
    })
  }

  for (var i = 0; i < removers.length; i++) {
    removers[i].addEventListener("click", function (event) {
      event.preventDefault()

      // optimistically switch the button and let the back-end process happen
      hideItems(removers)
      showItems(adders)

      let id = this.dataset.itemId
      if (id) {
        fetch(`/users/favourite/${id}`, { method: "DELETE" })
          .then(function () {
            return null
          })
          .catch(function (err) {
            console.warn(err)
          })
      }
      return false
    })
  }
})
