$(function () {
  var autoSubmit = function (element) {
    var form = $(element).parents("form")

    form.get(0).submit()
  }

  $("input[data-auto-submit]").on("change", function () {
    autoSubmit(this)
  })

  $("label[data-auto-submit]").on("click", function () {
    autoSubmit(this)
  })

  $("select[data-auto-submit]").on("change", function () {
    autoSubmit(this)
  })
})

window.clearHash = function () {
  var uri = window.location.toString()

  if (uri.indexOf("#") > 0) {
    var clean_uri = uri.substring(0, uri.indexOf("#"))

    window.history.replaceState({}, document.title, clean_uri)
  }
}
