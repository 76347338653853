$(function () {
  $("a.js-verification-trigger").on("click tap", function (e) {
    e.preventDefault()

    var id = $(e.target).data("id") || ""
    var email = $(e.target).data("email") || ""
    var url = "/users/resend-verification?userId=" + id + "&email=" + email

    fetch(url)
      .then(function (_resp) {
        // replace the text
        $(".js-verification-placeholder").html("Requested")
        return false
      })
      .catch(function (_err) {
        // leave the link in place
        return false
      })
  })
})
