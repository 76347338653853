// polyfilled for IE11
import URLSearchParams from "core-js/features/url-search-params"
import URL from "core-js/features/url"
import TimeMe from "timeme.js"

/** Trap click-throughs and trigger OCRM activities. */
$(function () {
  /**
   * Trap <a href=".."> clicks in the main body and
   * pass-and-forget them to raise Activities in OpenCRM.
   *
   * Links to other 55redefined.com pages are ignored.
   */
  $(".js-tracked a").on("click tap", function (e) {
    try {
      var a = $(e.target)
      var href = a.attr("href")
      if (!href) { // something inside an <A>..</A>
        href = $(e.target).parents("a").attr("href")
      }

      if (!href) {
        return;
      }

      let domains = [
        "jobs-redefined.co",
        "life-redefined.co",
        "work-redefined.co",
        "55redefined.co",
        "55redefined.com"
      ]
      let ownSite =
        !href.startsWith("http") ||
        domains.some(function (domain) {
          return href && new URL(href).hostname === domain
        })

      if (!ownSite) {
        var pageId = $(".js-tracking").data("trackingItemId") || ""
        let q = new URLSearchParams({
          target: href,
          pageId: pageId,
          t: new Date()
        })

        fetch("/_trackEmbeddedLink?" + q.toString())
          .then(function () {
            return null
          })
          .catch(function (err) {
            console.warn(`Attempting to link to ${href}`, err)
          })
      }
    } catch (err) {
      console.error(`Attempting to link to ${href}`, err)
    }
  })
})

/**
 * Trap callback integration clicks to record initiating
 * a callback, which the user may or may not complete.
 */
$("a.js-initiate-callback").on("click tap", function (e) {
  var partner = $(e.target).data("partner")
  fetch("/_trackCallbackStarted?partner=" + partner)
    .then(function () {
      return null
    })
    .catch(function (err) {
      console.warn(err)
    })
})

/** Set up a dwell time tracker. */
$(function () {
  let settings = $("div.js-tracking")
  if (!settings || settings.length === 0) {
    return
  }

  let config = $(settings[0]).data() ?? {}
  let rawTimeout = parseInt(config["trackingTimeout"])
  let timeout = isFinite(rawTimeout) && rawTimeout > 0 ? rawTimeout : 30

  TimeMe.initialize({
    // must be much greater than the call timeout otherwise
    // it's assumed the reader has gone for good
    idleTimeoutInSeconds: 60 * 60 * 24,

    // staring at the page without scrolling will count
    trackWhenUserGoesIdle: true,

    // triggers when cumulative time on this page reaches the timeout
    trackWhenUserLeavesPage: true
  })

  TimeMe.callAfterTimeElapsedInSeconds(timeout, function () {
    let tracker = $("div.js-tracking")

    if (tracker.length > 0) {
      let data = $(tracker[0]).data() || {}

      let itemId = data["trackingItemId"] || ""
      let categoryId =
        data["trackingCategoryId"] || data["trackingToplevelCategoryId"] || ""
      let title = data["trackingTitle"]

      let q = new URLSearchParams({
        itemId: itemId,
        categoryId: categoryId,
        title: title
      })

      fetch("/_trackContentRead?" + q.toString())
        .then(function () {})
        .catch(function (err) {
          console.warn(err)
        })
    }
  })
})
