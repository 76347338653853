/**
 * Calendly popup handler, triggered when someone clicks the 'request call' link.
 *
 * Only works if Calendly is defined by some prior script.
 * This is loaded directly into the browser so use vanilla Javascript.
 *
 * Accepts a JSON string which unpacks to a CalendlyData instance.
 */

$(function () {
  if (window.showCalendlyWidget === undefined) {
    window.showCalendlyWidget = function (optionString) {
      try {
        var haveCalendly = typeof Calendly !== "undefined"
        var data = JSON.parse(optionString) || {}

        if (haveCalendly && data.integrationUrl) {
          // this thing will have one or both of integration type and config id, plus page source
          var resolvedIntegration = [
            data.integrationType || "",
            data.configurationUID || "",
            data.pageId || ""
          ].join("|")

          var parameters = {
            url: data.integrationUrl,
            prefill: {
              name: data.subscriberName,
              email: data.subscriberEmail,
              customAnswers: data.customAnswers || {}
            },

            // pass through the integration and the member so that the webhook
            // can trigger the correct partner integration
            // this data will also be logged in Calendly so 55R can see who booked what
            utm: {
              utmSource: data.integrationSource,
              utmCampaign: resolvedIntegration,
              utmContent: data.subscriberId,
              utmMedium: data.registrationType
            }
          }

          Calendly.initPopupWidget(parameters)
        } else {
          console.warn("Not enough info to trigger calendly", optionString)
        }
      } catch (err) {
        console.warn("Failed to show calendly widget", err)
      }

      // required
      return false
    }
  }
})
