// Login redirect means building URLs with ?returnTo=/foo/bar#baz,
// which means careful encoding. The built-in URL / URLSearchParams
// can do this but it isn't supported at all by IE11. So we need
// *both* polyfills, and parcel can't back-fill by itself.
// Ignore VS code telling you one is unused
import URL from "core-js/features/url"
import URLSearchParams from "core-js/features/url-search-params"

$(function () {
  // this is included on sites without login enabled, so check to make sure lock is included
  if (
    typeof Auth0Lock === "undefined" ||
    typeof authenticationConfig === "undefined"
  ) {
    return
  }

  var publicSite = authenticationConfig.publicSite || "https://life-redefined.co/"

  var languageDictionary = {}

  var additionalSignUpFields = [
    {
      // on the user, not their metadata
      name: "given_name",
      placeholder: "Your first name",
      validator: function (the_name) {
        return {
          valid: the_name && the_name.trim().length > 0,
          hint: "Name can't be blank"
        }
      },
      storage: "root"
    },
    {
      // on the user, not their metadata
      name: "family_name",
      placeholder: "Your surname",
      validator: function (the_name) {
        return {
          valid: the_name && the_name.trim().length > 0,
          hint: "Name can't be blank"
        }
      },
      storage: "root"
    },
    {
      // on the metadata to avoid conflicting
      // with Auth0's own use of phone numbers
      name: "phone",
      placeholder: "Telephone (optional)",
      validator: function (the_title) {
        return true
      }
    }
  ]

  additionalSignUpFields.push({
    type: "hidden",
    name: "registration_type",
    placeholder: "Registration Type",
    value: "Member"
  })

  languageDictionary.signUpTitle = "Register"
  languageDictionary.signUpLabel = "Register"
  languageDictionary.signUpSubmitLabel = "Register"
  languageDictionary.signUpWithLabel = "Register with %s"
  languageDictionary.signUpTerms =
    `By registering, you agree to our <a href='${publicSite}info/formal-stuff#wtac'>Terms and Conditions</a> and <a href='${publicSite}info/formal-stuff#pacp'>Privacy Policy</a>`

  languageDictionary.error = {
    signUp: {
      "lock.fallback":
        "Registration unsuccessful.<br/>If you've already registered with this email address, please login with your existing password"
    }
  }

  var lock = new Auth0Lock(
    authenticationConfig.clientID,
    authenticationConfig.auth0Domain,
    {
      initialScreen: "login",
      auth: {
        redirectUrl: authenticationConfig.callback,
        responseType: "id_token",
        redirect: false
      },
      rememberLastLogin: true,
      languageDictionary: languageDictionary,
      theme: {
        logo: "https://life-redefined.co/images/logo-life.svg",
        primaryColor: "#E8500E"
      },
      closable: true,
      autoclose: true,
      defaultADUsernameFromEmailPrefix: false,

      allowForgotPassword: true,
      additionalSignUpFields: additionalSignUpFields
    }
  )

  // is registering as opposed to logging in
  var isRegistering = true

  lock.on("authenticated", function (authResult) {
    // if our state data looks like a local URL, use it.
    var state = authResult.state
    var returnTo = /^\//.test(state) ? state : "/"

    // This is /login, which goes through getLoginState in app.ts.
    // That end can't separate a new registration from a normal
    // login so we tell it on the query string, plus the url
    // to go after registration if it's set via Static Data.
    var u = new URL(authenticationConfig.redirectUrl)
    u.searchParams.append("returnTo", returnTo)
    u.searchParams.append("isRegistering", isRegistering)
    u.searchParams.append(
      "postRegistration",
      authenticationConfig.postRegistrationPage
    )
    window.location.href = u.toString()
  })

  lock.on("hide", function () {
    $("body").removeClass("prevent-scroll")
  })

  // ensure the sidebar copy is correct, triggered on all appearances.
  lock.on("show", function () {
    $(".auth0-lock-container").addClass("with-benefits")
    updateSidebarCopy()
  })

  // toggle sidebar content, triggered when the login/register side changes
  // and on the first appearance of this Lock popup, does *not* trigger on
  // 2nd and subsequent appearances if the side has not changed!
  lock.on("signin ready", function () {
    isRegistering = false
    updateSidebarCopy()
  })
  lock.on("signup ready", function () {
    isRegistering = true
    updateSidebarCopy()
  })

  $(".js-login").on("click", function (event) {
    event.preventDefault()

    $("body").addClass("prevent-scroll")

    var opts = buildReturnToOpts(event)
    lock.show(opts)
  })

  $('[href$="/users/register"]').on("click", function (event) {
    event.preventDefault()

    $("body").addClass("prevent-scroll")

    var opts = buildReturnToOpts(event, "signUp")
    lock.show(opts)
  })

  $(".js-register").on("click", function (event) {
    event.preventDefault()

    $("body").addClass("prevent-scroll")

    var opts = buildReturnToOpts(event, "signUp")
    lock.show(opts)
  })

  // switch sidebar copy between login and signup
  const updateSidebarCopy = function () {
    var copy = ""
    if (isRegistering) {
      copy = $("#member-benefits-copy").html()
    } else {
      copy = $("#login-popup-copy").html()
    }

    $(".benefits-copy").remove()
    if (copy && copy.length > 0) {
      var sidebar = $('<div class="benefits-copy">')
      sidebar.html(copy)

      var sidebarParent = $(".auth0-lock-content")
      sidebarParent.prepend(sidebar)
    }
  }

  const buildReturnToOpts = function (event, initialScreen) {
    // propagate the "return to" URL through the state parameter,
    // this is picked up later in lock.on("authenticated", ...)

    // the event target could be an A element or something within it.
    var direct = $(event.target).data("return-to")
    var register = $(event.target).parents(".js-register").data("return-to")
    var login = $(event.target).parents(".js-login").data("return-to")

    var returnTo = direct || register || login || "/"
    return {
      auth: {
        params: {
          state: returnTo
        }
      },
      initialScreen: initialScreen || "login"
    }
  }
})
