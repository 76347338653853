/** GA stuff. */
$(function () {
  if (window.dataLayer) {
    let tags = $("head").find("meta[name^='custom:']")

    if (tags.length > 0) {
      let message = { event: "record_page_metadata" }

      tags.each(function (_index, domElement) {
        let cashElement = $(domElement)
        let content = cashElement.attr("content")

        if (content) {
          message[cashElement.attr("name").replace("custom:", "")] = content
        }
      })

      if (Object.keys(message).length > 1) {
        window.dataLayer.push(message)
      }
    }
  }
})
