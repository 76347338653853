/** CLient-side validation for custom contact forms. */

$(function () {
  const revalidateInput = function (input) {
    try {
      let siblingMessages = $(input).siblings(".js-validate-message")
      let thisInputIsValid = input.checkValidity()
      if (thisInputIsValid) {
        $(input).removeClass("checked-invalid")
        siblingMessages.hide()
      } else {
        $(input).addClass("checked-invalid")
        siblingMessages.show()
        input.reportValidity()
      }

      return thisInputIsValid
    } catch (err) {
      console.warn("revalidateInput", err)
      return false
    }
  }

  const revalidateRadioButtonGroup = function (group) {
    try {
      let checks = []
      $(group)
        .find("input")
        .map((_ix, input) => {
          checks.push(input.checkValidity())
        })

      let anyValid = checks.length === 0 || checks.some((t) => t)
      if (anyValid) {
        $(group).find(".js-validate-message").hide()
      } else {
        $(group).find(".js-validate-message").show()
      }

      return anyValid
    } catch (err) {
      console.warn("revalidateRadioButtonGroup", err)
      return false
    }
  }

  $("form.js-custom-form .js-validate").on("change", function (event) {
    revalidateInput($(event.target).first()[0])
  })

  $("form.js-custom-form .js-validate-radio input").on(
    "change",
    function (event) {
      let group = $(event.target).closest(".js-validate-radio").first()[0]
      if (group) {
        revalidateRadioButtonGroup(group)
      }
    }
  )

  /** Run these as a background POST for time-consuming work. */
  $("form.js-contact-form-background").on("submit", function (e) {
    e.preventDefault()

    var form = $(e.target)
    var where = form.attr("action")
    var data = form.serialize()
    form.find(".js-contact-form-spinner").removeClass("hidden")
    fetch(where, {
      method: "POST",
      body: data,
      // redirect: "follow",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json"
      }
    })
      .then(function (response) {
        return response.json()
      })
      .then((response) => {
        window.location = response.url
      })
      .catch(function (err) {
        console.warn(err)
        form.find(".js-contact-form-spinner").addClass("hidden")
      })

    return false
  })
})
